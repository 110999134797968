var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.editForm)?_c('div',{staticClass:"hyphen-input-dash"},[_c('h2',[_vm._v("Field Settings")]),_c('div',{staticClass:"hyphen-input-dash__header"},[_c('div',{staticClass:"text-hint"},[_c('span',{domProps:{"innerHTML":_vm._s(_setup.alert)}}),_c('span',[_vm._v("Arithmetic")])]),_c('p',[_vm._v(" Choose the math operation (add, subtract, multiply, divide), and specify the two fields it should operate on. This field will then show the result of the mathematical operation. ")])]),_c('div',{staticClass:"hyphen-input-dash__content"},[_c(_setup.HyphenInput,{attrs:{"id":"dash-input-label","placeholder":"Enter label","label":"Label","description":"Label of input you want to create"},model:{value:(_setup.editForm.label),callback:function ($$v) {_vm.$set(_setup.editForm, "label", $$v)},expression:"editForm.label"}}),_c(_setup.HyphenSelect,{attrs:{"label":"Select Formula","placeholder":"Select ","description":"Select the formula you want to apply","option-label":"label","option-value":"value","options":[
        {
          label: 'Add',
          value: 'add',
        },
        {
          label: 'Subtract',
          value: 'subtract',
        },
        {
          label: 'Multiply',
          value: 'multiply',
        },
        {
          label: 'Divide',
          value: 'divide',
        },
      ]},model:{value:(_setup.editForm.operations[0]),callback:function ($$v) {_vm.$set(_setup.editForm.operations, 0, $$v)},expression:"editForm.operations[0]"}}),_c('div',[_c('label',{staticClass:"hyphen-input-dash__label"},[_vm._v("Select Fields to Apply Formula ")]),_c('div',[_vm._l((_setup.editForm.dependencies),function(input,index){return _c('div',{key:index},[_c('div',{staticClass:"hyphen-input",staticStyle:{"padding-top":"8px"}},[_c('div',{staticClass:"hyphen-input__input-wrapper"},[_c(_setup.HyphenSelect,{staticClass:"input-target",attrs:{"label":`Select Field ${_setup.indexInWords(index)}`,"placeholder":`Option ${_setup.indexInWords(index)}`,"description":!_setup.editForm.dependencies[index].id
                    ? `Select field you want to apply the formula on`
                    : '',"option-label":"label","option-value":"id","error":_setup.editForm.dependencies[index].id
                    ? `${
                        !_setup.availableFields.find(
                          (field) =>
                            field.id === _setup.editForm.dependencies[index].id
                        ).required
                          ? 'This field should be required'
                          : ''
                      }`
                    : '',"options":_setup.availableFields,"exculde-options":_setup.editForm.dependencies.map((dependency) => dependency.id)},model:{value:(_setup.editForm.dependencies[index].id),callback:function ($$v) {_vm.$set(_setup.editForm.dependencies[index], "id", $$v)},expression:"editForm.dependencies[index].id"}}),_c('div',{staticClass:"input-suffix"},[_c('span',{staticClass:"icon-x",domProps:{"innerHTML":_vm._s(_setup.hoverIndex === index ? _setup.closeHover : _setup.close)},on:{"click":function($event){return _setup.removeInput(index)},"mouseenter":function($event){_setup.hoverIndex = index},"mouseleave":function($event){_setup.hoverIndex = -1}}})])],1)])])}),(_setup.editForm.dependencies.length < _setup.availableFields.length)?_c('span',{staticClass:"add-button",on:{"click":_setup.addInput}},[_vm._v("+ Add option")]):_vm._e()],2)]),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-placeholder","placeholder":"Enter placeholder text","label":"Placeholder (optional)","description":"Placeholder of input you want to create"},model:{value:(_setup.editForm.placeholder),callback:function ($$v) {_vm.$set(_setup.editForm, "placeholder", $$v)},expression:"editForm.placeholder"}}),_c(_setup.HyphenInput,{attrs:{"id":"dash-input-description","placeholder":"Enter description text","label":"Description (optional)","description":"Description of input you want to create"},model:{value:(_setup.editForm.description),callback:function ($$v) {_vm.$set(_setup.editForm, "description", $$v)},expression:"editForm.description"}}),_c(_setup.HyphenSelect,{attrs:{"label":"Required","placeholder":"Select a type","description":"Is this field required?","option-label":"label","option-value":"value","options":[
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ]},model:{value:(_setup.editForm.required),callback:function ($$v) {_vm.$set(_setup.editForm, "required", $$v)},expression:"editForm.required"}})],1),_c('div',{staticClass:"hyphen-input-dash__action"},[_c('button',{staticClass:"cancel",on:{"click":_setup.cancel}},[_vm._v("Cancel")]),(_setup.canApply)?_c('button',{staticClass:"apply",class:{
        'apply--disabled': !_setup.canApply,
      },on:{"click":_setup.apply}},[_vm._v(" Apply ")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }