<!-- eslint-disable no-unused-vars -->
<script setup>
import { defineProps, ref, watch, defineEmits, computed } from "vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },

  value: {
    type: [Object, String],
    default: "",
  },

  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "auto",
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  description: {
    type: String,
    default: "",
  },
  inputStyle: {
    type: Object,
    default: () => {},
  },
  required: {
    type: Boolean,
    default: false,
  },
  action: {
    type: String || null,
    default: null,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  dependencies: {
    type: Array,
    default: () => [],
  },
  operations: {
    type: Array,
    default: () => [],
  },
  stream: {
    type: [Array, Object],
    default: () => {},
  },
});

const opperands = {
  add: (a, b) => a + b,
  subtract: (a, b) => a - b,
  multiply: (a, b) => a * b,
  divide: (a, b) => a / b,
};

const convertToRaw = (value) => {
  return value.toString().replace(/,/g, "");
};

const addCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const formatNumber = (amount) => {
  amount = convertToRaw(amount);
  if (!/^[0-9]+$/.test(amount)) {
    return "";
  }
  return addCommas(amount);
};

const formatMoney = (amount) => {
  amount = convertToRaw(amount);

  if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(amount)) {
    return "";
  }

  return addCommas(addKobo(amount));
};

const addKobo = (target) => {
  let val = target;

  if (isNaN(parseFloat(target))) {
    return val;
  }

  // check if target ends with .
  if (target.endsWith(".")) {
    const newV = target + "00";
    val = newV.trim();
  } else if (target.indexOf(".") == -1) {
    const newV = target + ".00";
    val = newV.trim();
  } else {
    // already has dot
    const newV = parseFloat(target).toFixed(2);
    val = newV.trim();
  }

  // count number of dot in target
  const dotCount = (val.match(/\./g) || []).length;
  if (dotCount > 1) {
    // remove everything after the first dot
    val = val.slice(0, val.indexOf(".") + 1);
  }

  return val;
};

const resultA = computed(() => {
  const streamData = props.stream?.data || props.stream;
  let calc = props.dependencies.map((dep) => {
    const tar = streamData.find((stream) => stream.id === dep.id)?.value;
    return parseFloat(tar?.toString() || 0);
  });

  if (props.operations[0] === "add") {
    calc = calc.reduce(
      (acc, dep) => opperands[props.operations[0]](acc, dep),
      0
    );
  } else {
    const [max, min] = [Math.max(...calc), Math.min(...calc)];
    calc = opperands[props.operations[0]](max, min);
  }

  const hasMoney = streamData.filter(
    (stream) => stream.attributes?.type === "money" || stream?.type === "money"
  )?.length;

  const result = hasMoney ? formatMoney(calc) : formatNumber(calc);
  return result === "0" || result === "0.00" ? "" : result;
});

const showError = ref(false);

const collectFeedback = computed(() => {
  let error = {
    hasError: false,
    message: "",
  };

  return {
    error,
  };
});

const canShowError = computed(() => {
  return showError.value && collectFeedback.value.error.hasError;
});

const emit = defineEmits({
  input(val) {
    return val;
  },
  feedback(val) {
    return val;
  },
});

watch(
  resultA,
  (val) => {
    emit("input", convertToRaw(val));

    emit("feedback", collectFeedback.value);
  },
  { immediate: true }
);

watch(
  () => props.action,
  (val) => {
    switch (val) {
      case "showError":
        showError.value = true;
        break;
      case "hideError":
        showError.value = false;
        break;
      default:
        showError.value = false;
        break;
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="hyphen-arithmetic" :style="{ width: width }">
    <label class="hyphen-arithmetic__label" :for="props.id">{{
      props.label
    }}</label>
    <div
      class="hyphen-arithmetic__input-wrapper"
      :style="{ backgroundColor: backgroundColor }"
    >
      <div
        class="input-target"
        :style="{ height: height, ...inputStyle }"
        :id="props.id"
      >
        {{ resultA }}
      </div>
    </div>
    <div
      class="hyphen-arithmetic__description-text"
      :class="{ 'hyphen-component-error ': canShowError }"
    >
      {{ canShowError ? collectFeedback.error.message : props.description }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-arithmetic {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Inter;
  gap: 6px;
  box-sizing: border-box;

  .hyphen-component-error {
    color: #ff0000;
  }

  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1.8px dashed #d9dee1;
    padding: 12px 0px;
    background-color: transparent;

    .input-prefix {
      margin-left: 8px;
    }

    .input-target {
      flex: 1;
      min-width: auto;
      outline-style: none;
      border-radius: inherit;
      background-color: inherit;
      border: none;
      color: #666;
      font-size: 16px;
      padding: 10px;
    }

    .input-suffix {
      margin-right: 8px;
    }
  }

  &__description-text {
    font-size: 12px;
    color: #797e86;
  }
}
</style>
