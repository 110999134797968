<script setup>
import { defineProps, ref, watch, defineEmits, computed } from "vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "auto",
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  description: {
    type: String,
    default: "",
  },
  inputStyle: {
    type: Object,
    default: () => {},
  },
  required: {
    type: Boolean,
    default: false,
  },
  action: {
    type: String || null,
    default: null,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  step: {
    type: String,
    default: "1",
  },
  max: {
    type: String,
    default: "100",
  },
  min: {
    type: String,
    default: "0",
  },
  unit: {
    type: String,
    default: null,
  },
});

const inputValue = ref("");
const rangeValue = ref(0);

const convertToRaw = (value) => {
  return value.toString().replace(/,/g, "");
};

const addCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const formatNumber = () => {
  let amount = convertToRaw(inputValue.value);
  if (!/^[0-9]+$/.test(amount)) {
    inputValue.value = "";
    return;
  }
  if (amount) {
    inputValue.value = props.unit + "" + addCommas(amount);
  }
};

const formatMoney = () => {
  let amount = inputValue.value;
  amount = convertToRaw(amount);

  if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(amount)) {
    inputValue.value = "";
    return;
  }

  if (amount) {
    inputValue.value = props.unit + "" + addCommas(addKobo(amount));
  }
};

const addKobo = (target) => {
  let val = target;

  if (isNaN(parseFloat(target))) {
    return val;
  }

  // check if target ends with .
  if (target.endsWith(".")) {
    const newV = target + "00";
    val = newV.trim();
  } else if (target.indexOf(".") == -1) {
    const newV = target + ".00";
    val = newV.trim();
  } else {
    // already has dot
    const newV = parseFloat(target).toFixed(2);
    val = newV.trim();
  }

  // count number of dot in target
  const dotCount = (val.match(/\./g) || []).length;
  if (dotCount > 1) {
    // remove everything after the first dot
    val = val.slice(0, val.indexOf(".") + 1);
  }

  return val;
};

const formatValue = () => {
  if (props.type === "money") {
    formatMoney();
  } else if (props.type === "number") {
    formatNumber();
  }
};

const showError = ref(false);

const collectFeedback = computed(() => {
  let error = {
    hasError: false,
    message: "",
  };

  if (rangeValue.value) {
    if (props.type === "number") {
      if (isNaN(parseFloat(convertToRaw(rangeValue.value)))) {
        error.hasError = true;
        error.message = `${props.label} must be a valid number`;
      }
    } else if (props.type === "money") {
      if (isNaN(parseFloat(convertToRaw(rangeValue.value)))) {
        error.hasError = true;
        error.message = `${props.label} must be a valid amount`;
      }
    }
  } else if (
    (!rangeValue.value || Number(props?.min) > Number(rangeValue.value)) &&
    props.required
  ) {
    error.hasError = true;
    error.message = `${props.label} is required`;
  }

  return {
    error,
  };
});

const canShowError = computed(() => {
  return showError.value && collectFeedback.value.error.hasError;
});

const emit = defineEmits({
  input(val) {
    return val;
  },
  feedback(val) {
    return val;
  },
});

watch(
  rangeValue,
  (val) => {
    inputValue.value = val;
    formatValue();

    emit("feedback", collectFeedback.value);
    emit("input", val + "");
  },
  { immediate: true }
);

watch(
  () => props.value,
  (val) => {
    if (val !== rangeValue.value) {
      rangeValue.value = Number(val) || 0;
    }
  },
  { immediate: true }
);

watch(
  () => props.action,
  (val) => {
    switch (val) {
      case "showError":
        showError.value = true;
        break;
      case "hideError":
        showError.value = false;
        break;
      default:
        showError.value = false;
        break;
    }
  },
  { immediate: true }
);

const percentage = computed(() => {
  return (rangeValue.value / props.max) * 100;
});

// watch percentage and set background-size
watch(
  percentage,
  (val) => {
    document.documentElement.style.setProperty("--percentage", `${val}%`);
  },
  { immediate: true }
);
</script>

<template>
  <div class="hyphen-input" :style="{ width: width }">
    <label class="hyphen-input__label" :for="props.id">{{ props.label }}</label>
    <div
      class="hyphen-input__input-wrapper"
      :style="{ backgroundColor: backgroundColor }"
    >
      <div class="input-prefix"></div>
      <input
        class="input-target"
        :style="{ height: height, ...inputStyle }"
        :id="props.id"
        v-model="inputValue"
        :placeholder="props.placeholder"
        :required="props.required"
        disabled
      />
      <div class="input-suffix"></div>
    </div>
    <div class="hyphen-input__range">
      <input
        type="range"
        :min="props.min"
        :max="props.max"
        :step="props.step"
        v-model="rangeValue"
      />
    </div>
    <div
      class="hyphen-input__description-text"
      :class="{ 'hyphen-component-error ': canShowError }"
    >
      {{ canShowError ? collectFeedback.error.message : props.description }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-input {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Inter;
  gap: 6px;
  box-sizing: border-box;

  .hyphen-component-error {
    color: #ff0000;
  }

  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    border: 1.8px solid #d9dee1;
    border-radius: 5px;
    padding: 12px 0px;
    background-color: transparent;

    .input-prefix {
      margin-left: 8px;
    }

    .input-target {
      flex: 1;
      min-width: auto;
      outline-style: none;
      border-radius: inherit;
      background-color: inherit;
      border: none;
      color: #666;
      font-size: 15px;
    }

    .input-suffix {
      margin-right: 8px;
    }
  }

  &__range {
    /********** Range Input Styles **********/
    /*Range Reset*/
    input[type="range"] {
      width: 100%;
      -webkit-appearance: none;
      appearance: none;
      background: none;
      background-color: #f2f5f9;
      height: 5px;
      border-radius: 4px;

      &::-webkit-slider-thumb {
        border: none;
        -webkit-appearance: none;
        width: 14px;
        height: 14px;
        box-sizing: border-box;
        background-color: #fff;
        border: 3px solid #111729;
        border-radius: 50%;
      }

      &::-moz-range-thumb {
        border: none;
        -webkit-appearance: none;
        appearance: none;
        width: 14px;
        height: 14px;
        box-sizing: border-box;
        background-color: #fff;
        border: 3px solid #111729;
        border-radius: 50%;
      }

      background-image: linear-gradient(#111729, #111729);
      background-size: var(--percentage) 100%;
      background-repeat: no-repeat;
    }
  }
  &__description-text {
    font-size: 12px;
    color: #797e86;
  }
}
</style>
