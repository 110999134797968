<script setup>
import { defineProps, computed, ref, watch } from "vue";
// import HyphenInput from "./HyphenInput.vue";
import compoentSchema from "../../form-components.js";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  value: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    default: () => [],
  },
  rowData: {
    type: Array,
    default: () => [
      // {
      //   label: "Column 1",
      //   type: "input",
      //   value: "",
      //   attributes: {
      //     type: "text",
      //     placeholder: "",
      //     description: "this is a description for column 1",
      //   },
      // },
      // {
      //   label: "Column 2",
      //   type: "input",
      //   value: "",
      //   attributes: {
      //     type: "text",
      //     placeholder: "",
      //     description: "this is a description for column 2",
      //   },
      // },
    ],
  },
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "auto",
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  columns: {
    type: Number,
    default: 1,
  },
  maxRows: {
    type: Number,
    default: 2,
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

const numberOfrows = computed(() => {
  return props.maxRows;
});

const items = ref([]);

const randomAlphabet = () => {
  const [min, max] = [1, 5]; // random length of alphabets
  const alphabets = "abcdefghijklmnopqrstuvwxyz";
  const randomLength = Math.floor(Math.random() * (max - min + 1)) + min;
  let result = "";
  for (let i = 0; i < randomLength; i++) {
    result += alphabets.charAt(Math.floor(Math.random() * alphabets.length));
  }

  return result;
};

const randomNumber = () => {
  return Math.floor(Math.random() * 4500);
};

const getInputCompoent = (component, index) => {
  let cc = compoentSchema.find((c) => c.type === component.type);
  let uniqueId = `${randomAlphabet()}${Date.now()}${randomNumber()}`;
  return {
    ...cc,
    id: uniqueId,
    ...props.rowData[index],
    key: undefined,
    required: undefined,
  };
};

const addRow = () => {
  var options = [];
  for (let i = 0; i < props.columns; i++) {
    options.push(getInputCompoent({ type: "input" }, i));
  }

  const newROw = {
    key: "row_" + parseInt(items.value.length + 1),
    options: options,
  };

  items.value.push(newROw);
};

const emit = defineEmits({
  input(val) {
    return val;
  },
  feedback(val) {
    return val;
  },
});

const optionArr = computed(() => {
  return props.options.map((row) => {
    return {
      key: row.key,
      options: row.options.map((col, index) => {
        return {
          type: props.rowData[index].type,
          id: col.id,
          label: props.rowData[index].label,
          value: col.value,
          attributes: props.rowData[index].attributes,
        };
      }),
    };
  });
});

watch(
  () => optionArr.value,
  (val) => {
    if (JSON.stringify(val) !== JSON.stringify(items.value)) {
      items.value = val;
    }

    if (val.length === 0) {
      addRow();
    }
  },
  { immediate: true }
);

watch(
  () => items.value,
  (val) => {
    emit(
      "input",
      val.map((item) => {
        return {
          key: item.key,
          options: item.options.map((bob) => {
            return {
              id: bob.id,
              value: bob.value,
            };
          }),
        };
      })
    );
  },
  { deep: true },
  { immediate: true }
);
</script>

<template>
  <div class="hyphen-list" :style="{ width: props.width }">
    <div class="hyphen-list__header">
      <span v-for="(header, x) in rowData" :key="x">
        {{ header.label }}
      </span>
    </div>
    <div class="hyphen-list__data">
      <div v-for="(item, i) in items" :key="i" class="hyphen-list__data__row">
        <div
          class="hyphen-list__data__row-wrapper"
          v-for="(option, x) in item.options"
          :key="x"
        >
          <input
            v-if="option.type === 'input'"
            :background-color="backgroundColor"
            v-model="items[i].options[x].value"
            :type="option.attributes.type"
            :style="{ backgroundColor: backgroundColor }"
            :placeholder="option.attributes.placeholder || ''"
          />
          <select
            v-else-if="option.type === 'select'"
            :background-color="backgroundColor"
            v-model="items[i].options[x].value"
            :style="{ backgroundColor: backgroundColor }"
            :placeholder="option.attributes.placeholder || ''"
          >
            <option
              v-for="(option, index) in option.options"
              :key="index"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="hyphen-list__description">
      <span v-for="(footer, y) in rowData" :key="y">
        {{ footer.attributes.description }}
      </span>
    </div>
    <div>
      <button
        class="hyphen-list__add"
        @click="addRow"
        type="button"
        v-if="items.length < numberOfrows"
      >
        +add another
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hyphen-list {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  box-sizing: border-box;
  gap: 0px;

  .hyphen-component-error {
    color: #ff0000;
  }

  &__header {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 6px;

    @media (max-width: 768px) {
      gap: 0px;
    }

    span {
      flex: 1;
      color: rgba(25, 40, 61, 0.8);
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      font-weight: 500;
    }
  }

  &__data {
    @media (max-width: 768px) {
      border-radius: 5px;
      border: 1.8px solid #d9dee1;
    }

    &__row {
      display: flex;
      gap: 10px;
      overflow: auto;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        gap: 0px;
        margin-bottom: 0px;
        border-bottom: 1.8px solid #d9dee1;

        &:last-child {
          border-bottom: none;
        }
      }

      &-wrapper {
        display: flex;
        flex: 1;
        overflow: auto;
        border-radius: 5px;
        background-color: v-bind(backgroundColor);

        @media (max-width: 768px) {
          border-radius: 0px;

          &:not(:first-child) {
            border-left: 1.8px solid #d9dee1;
          }
        }
      }

      input,
      select {
        border: 1.8px solid #d9dee1;
        border-radius: 5px;
        padding: 12px;
        background-color: transparent;
        width: 100%;
        flex: 1;
        min-width: auto;
        outline-style: none;
        background-color: inherit;
        color: #666;
        font-size: 15px;

        @media (max-width: 768px) {
          border: none;
          border-radius: 0px;

          &:not(:first-child) {
            border-left: 1.8px solid #d9dee1;
          }
        }
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 6px;

    @media (max-width: 768px) {
      gap: 0px;
    }

    span {
      flex: 1;
      font-size: 12px;
      color: #797e86;
    }
  }

  &__add {
    float: right;
    margin-top: 20px;
    border: none;
    background-color: transparent;
    font-family: Inter;
    font-size: 14px;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--body-text, rgba(25, 40, 61, 0.8));

    &:hover {
      text-decoration-line: underline;
    }
  }
}
</style>
