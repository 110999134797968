<script setup>
import { defineProps, onMounted, ref, watch } from "vue";
import HyphenInput from "../base/HyphenInput.vue";
import HyphenRange from "../base/HyphenRange.vue";
import HyphenArithmetic from "../base/HyphenArithmetic.vue";
import HyphenSensitive from "../base/HyphenSensitive.vue";
import HyphenTextarea from "../base/HyphenTextarea.vue";
import HyphenContent from "../base/HyphenContent.vue";
import HyphenFileInput from "../base/HyphenFileInput.vue";
import HyphenSignature from "../base/HyphenSignature.vue";
import HyphenSelect from "../base/HyphenSelect.vue";
import HyphenCheckList from "../base/HyphenCheckList.vue";
import HyphenData from "../base/HyphenData.vue";
import HyphenTable from "../base/HyphenTable.vue";
import HyphenList from "../base/HyphenList.vue";
import HyphenAuth from "../base/HyphenAuth.vue";

const props = defineProps({
  form: {
    type: Object,
    default: () => {},
  },
  formAction: {
    type: String || null,
    default: null,
  },
  stream: {
    type: [Array, Object],
    default: () => [],
  },
});

const feedback = ref({
  hasError: false,
  message: "",
});

const inputValue = ref({
  value: props.form.value || "",
  ...props.form,
});

const emit = defineEmits({
  update(val) {
    return val;
  },

  verificationCode(val) {
    return val;
  },
});

watch(
  () => inputValue.value,
  (val) => {
    if (JSON.stringify(val) !== JSON.stringify(props.form)) {
      emit("update", { ...val, feedback });
    }
  },
  { deep: true }
);

watch(
  () => props.form,
  (val) => {
    inputValue.value = {
      value: val.value || "",
      ...val,
    };
  },
  { deep: true },
  { immediate: true }
);

onMounted(() => {
  emit("update", {
    ...inputValue.value,
    feedback,
  });
});
</script>
<template>
  <div class="hyphen-form-render-row" v-if="props.form && props.form.type">
    <hyphen-auth
      v-if="props.form.type === 'authentication'"
      :id="props.form.id"
      @verificationCode="emit('verificationCode', $event)"
      :error="props.form.error"
      background-color="#F6F8F8"
      :label="props.form.label"
      :description="props.form.attributes.description"
      :placeholder="props.form.attributes.placeholder"
    />

    <hyphen-input
      v-model="inputValue.value"
      v-if="props.form.type === 'input'"
      :action="formAction"
      :editable="props.form.attributes.editable ?? true"
      :id="props.form.id"
      @feedback="feedback = $event"
      background-color="#F6F8F8"
      :placeholder="props.form.attributes.placeholder"
      :label="props.form.label"
      :type="props.form.attributes.type"
      :description="props.form.attributes.description"
      :required="props.form.required"
    />

    <hyphen-range
      v-model="inputValue.value"
      v-if="props.form.type === 'range'"
      :action="formAction"
      :editable="props.form.attributes.editable ?? true"
      :id="props.form.id"
      @feedback="feedback = $event"
      background-color="#F6F8F8"
      :placeholder="props.form.attributes.placeholder"
      :label="props.form.label"
      :type="props.form.attributes.type"
      :description="props.form.attributes.description"
      :required="props.form.required"
      :max="props.form.attributes.max"
      :min="props.form.attributes.min"
      :step="props.form.attributes.step"
      :unit="props.form.attributes.unit"
    />

    <hyphen-arithmetic
      v-model="inputValue.value"
      v-if="props.form.type === 'arithmetic'"
      :action="formAction"
      :id="props.form.id"
      @feedback="feedback = $event"
      background-color="#F6F8F8"
      :placeholder="props.form.attributes.placeholder"
      :label="props.form.label"
      :description="props.form.attributes.description"
      :operations="props.form.operations"
      :dependencies="props.form.dependencies"
      :required="props.form.required"
      :stream="props.stream"
    />

    <hyphen-sensitive
      v-model="inputValue.value"
      v-if="props.form.type === 'sensitive'"
      :action="formAction"
      :editable="props.form.attributes.editable ?? true"
      :id="props.form.id"
      @feedback="feedback = $event"
      background-color="#F6F8F8"
      :placeholder="props.form.attributes.placeholder"
      :label="props.form.label"
      :type="props.form.attributes.type"
      :description="props.form.attributes.description"
      :required="props.form.required"
    />

    <hyphen-textarea
      v-model="inputValue.value"
      v-if="props.form.type === 'multi-line-input'"
      :editable="props.form.attributes.editable ?? true"
      :action="formAction"
      :id="props.form.id"
      @feedback="feedback = $event"
      background-color="#F6F8F8"
      :placeholder="props.form.attributes.placeholder"
      :label="props.form.label"
      :description="props.form.attributes.description"
      :max-line="props.form.attributes.maxLine"
      :default-line="props.form.attributes.defaultLine"
      :required="props.form.required"
    />

    <hyphen-content
      :value="props.form.value"
      v-if="props.form.type === 'content'"
      :action="formAction"
      :id="props.form.id"
      @feedback="
        feedback = {
          hasError: false,
          message: '',
        }
      "
      :type="props.form.attributes.type"
      :required="props.form.required"
    />

    <hyphen-file-input
      v-model="inputValue.value"
      v-if="props.form.type === 'file'"
      :editable="props.form.attributes.editable ?? true"
      :action="formAction"
      :id="props.form.id"
      @feedback="feedback = $event"
      background-color="#F6F8F8"
      :placeholder="props.form.attributes.placeholder"
      :label="props.form.label"
      :types="props.form.attributes.types"
      :description="props.form.attributes.description"
      :size-limit="props.form.attributes.sizeLimit"
      :required="props.form.required"
    />
    <hyphen-signature
      v-if="props.form.type === 'signature'"
      v-model="inputValue.value"
      :action="formAction"
      :editable="props.form.attributes.editable ?? true"
      :id="props.form.id"
      background-color="#F6F8F8"
      :placeholder="props.form.attributes.placeholder"
      :label="props.form.label"
      :description="props.form.attributes.description"
      :required="props.form.required"
      :width="props.form.attributes.width"
      :height="props.form.attributes.height"
      @feedback="feedback = $event"
    />
    <hyphen-select
      v-if="props.form.type === 'select'"
      v-model="inputValue.value"
      :action="formAction"
      :editable="props.form.attributes.editable ?? true"
      :id="props.form.id"
      @feedback="feedback = $event"
      background-color="#F6F8F8"
      :placeholder="props.form.attributes.placeholder"
      :label="props.form.label"
      option-label="label"
      option-value="value"
      :options="props.form.options"
      :description="props.form.attributes.description"
      :required="props.form.required"
    />

    <hyphen-check-list
      v-if="props.form.type === 'checklist'"
      :label="props.form.label"
      :id="props.form.id"
      background-color="#F6F8F8"
      v-model="inputValue.value"
      option-label="label"
      option-value="value"
      @feedback="feedback = $event"
      :action="formAction"
      :editable="props.form.attributes.editable ?? true"
      :options="props.form.options"
      :description="props.form.attributes.description"
      :required="props.form.required"
    />

    <hyphen-data
      v-model="inputValue.value"
      v-if="props.form.type === 'data'"
      :id="props.form.id"
      :action="formAction"
      background-color="#F6F8F8"
      :placeholder="props.form.attributes.placeholder"
      :label="props.form.label"
      option-label="label"
      option-value="value"
      :source="props.form.attributes.source"
      :choice="props.form.attributes.choice"
      :data="props.form.attributes.data"
      :description="props.form.attributes.description"
      :required="props.form.required"
    />

    <hyphen-table
      v-model="inputValue.value"
      v-if="props.form.type === 'table'"
      :id="props.form.id"
      :action="formAction"
      background-color="#F6F8F8"
      :label="props.form.label"
      option-label="label"
      option-value="value"
      :source="props.form.attributes.source"
      :choice="props.form.attributes.choice"
      :data="props.form.attributes.data"
      :description="props.form.attributes.description"
      :required="props.form.required"
    />

    <hyphen-list
      v-model="inputValue.value"
      v-if="props.form.type === 'list'"
      :editable="props.form.attributes.editable ?? true"
      :id="props.form.id"
      :action="formAction"
      background-color="#F6F8F8"
      @feedback="
        feedback = {
          hasError: false,
          message: '',
        }
      "
      :columns="props.form.attributes.column"
      :max-rows="props.form.attributes.maxRows"
      :row-data="props.form.rowData"
      :options="props.form.options"
      :required="props.form.required"
    />
  </div>
</template>
<style lang="scss" scoped></style>
