<script setup>
import { computed, defineProps, ref, watch } from "vue";
import { alert } from "../../../svgs.js";
import HyphenInput from "../../base/HyphenInput.vue";
import HyphenSelect from "../../base/HyphenSelect.vue";
import numberToWords from "@/numberToWords.js";

const close = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="#D9DEE1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M18 6L6 18" />
    <path stroke="#D9DEE1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M6 6L18 18" />
  </svg>
`;
const closeHover = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 6L6 18" stroke="#FF6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 6L18 18" stroke="#FF6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const props = defineProps({
  form: {
    type: Object,
    default: null,
  },
  stream: {
    type: Array,
    default: () => [],
  },
});

const editForm = ref({
  label: "",
  placeholder: "",
  description: "",
  required: false,
  operations: [],
  dependencies: [],
});

const emit = defineEmits({
  edit(val) {
    return val;
  },
  remove(val) {
    return val;
  },
  cancelEdit(val) {
    return val;
  },
});

watch(
  () => props.form,
  (val) => {
    if (val) {
      editForm.value = {
        label: val.label,
        placeholder: val.attributes.placeholder,
        description: val.attributes.description,
        operations: val.operations,
        dependencies:
          val.dependencies.length > 0
            ? [...val.dependencies]
            : [
                {
                  id: "",
                },
              ], // Copy options
        required: val.required.toString(),
      };
    }
  },
  { immediate: true }
);

const canApply = computed(() => {
  return (
    editForm.value.label &&
    editForm.value.dependencies.every((dep) => dep.id) &&
    editForm.value.dependencies.length > 1
  );
});

const indexInWords = computed(() => {
  return (index) => {
    return numberToWords(index + 1);
  };
});

const apply = () => {
  const data = {
    id: props.form.id,
    value: props.form.value,
    type: props.form.type,
    label: editForm.value.label,
    key: key.value,
    required: editForm.value.required === "true" ? true : false,
    locked: props.form.locked || false,
    operations: editForm.value.operations,
    dependencies: editForm.value.dependencies.map((dependency) => {
      return {
        id: dependency.id,
      };
    }),
    attributes: {
      placeholder: editForm.value.placeholder,
      description: editForm.value.description,
      editable: props.form.attributes.editable ?? true,
    },
  };
  emit("edit", data);
};

const key = computed(() => {
  // convert string to snake case
  return editForm.value.label
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/[^\w-]+/g, "");
});

const cancel = () => {
  if (!props.form.label) {
    emit("remove", props.form);
  } else {
    emit("cancelEdit", props.form);
  }
};

const addInput = () => {
  // Check if editForm.value.options is undefined, and initialize it as an empty array if necessary
  if (!editForm.value.dependencies) {
    editForm.value.options = [
      {
        id: "",
      },
    ];
  } else {
    editForm.value.dependencies.push({
      id: "",
    });
  }
};

const hoverIndex = ref(-1);

// Function to remove an input from the editForm's dependencies array
const removeInput = (index) => {
  if (editForm.value.dependencies && editForm.value.dependencies.length > 1) {
    editForm.value.dependencies.splice(index, 1);
  } // Remove the input at the specified index
};

const availableFields = computed(() => {
  // filter type number| money
  return props.stream
    .filter(
      (field) =>
        field.attributes.type === "number" || field.attributes?.type === "money"
    )
    .map((field) => {
      return {
        id: field.id,
        label: field.label,
        type: field.attributes.type,
        required: field.required,
      };
    });
});
</script>
<template>
  <div v-if="editForm" class="hyphen-input-dash">
    <h2>Field Settings</h2>
    <div class="hyphen-input-dash__header">
      <div class="text-hint">
        <span v-html="alert"></span><span>Arithmetic</span>
      </div>
      <p>
        Choose the math operation (add, subtract, multiply, divide), and specify
        the two fields it should operate on. This field will then show the
        result of the mathematical operation.
      </p>
    </div>

    <div class="hyphen-input-dash__content">
      <hyphen-input
        v-model="editForm.label"
        id="dash-input-label"
        placeholder="Enter label"
        label="Label"
        description="Label of input you want to create"
      />

      <hyphen-select
        v-model="editForm.operations[0]"
        label="Select Formula"
        placeholder="Select "
        description="Select the formula you want to apply"
        option-label="label"
        option-value="value"
        :options="[
          {
            label: 'Add',
            value: 'add',
          },
          {
            label: 'Subtract',
            value: 'subtract',
          },
          {
            label: 'Multiply',
            value: 'multiply',
          },
          {
            label: 'Divide',
            value: 'divide',
          },
        ]"
      />

      <div>
        <label class="hyphen-input-dash__label"
          >Select Fields to Apply Formula
        </label>
        <div>
          <div v-for="(input, index) in editForm.dependencies" :key="index">
            <div class="hyphen-input" style="padding-top: 8px">
              <div class="hyphen-input__input-wrapper">
                <hyphen-select
                  v-model="editForm.dependencies[index].id"
                  class="input-target"
                  :label="`Select Field ${indexInWords(index)}`"
                  :placeholder="`Option ${indexInWords(index)}`"
                  :description="
                    !editForm.dependencies[index].id
                      ? `Select field you want to apply the formula on`
                      : ''
                  "
                  option-label="label"
                  option-value="id"
                  :error="
                    editForm.dependencies[index].id
                      ? `${
                          !availableFields.find(
                            (field) =>
                              field.id === editForm.dependencies[index].id
                          ).required
                            ? 'This field should be required'
                            : ''
                        }`
                      : ''
                  "
                  :options="availableFields"
                  :exculde-options="
                    editForm.dependencies.map((dependency) => dependency.id)
                  "
                />
                <div class="input-suffix">
                  <span
                    class="icon-x"
                    @click="removeInput(index)"
                    v-html="hoverIndex === index ? closeHover : close"
                    @mouseenter="hoverIndex = index"
                    @mouseleave="hoverIndex = -1"
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <span
            v-if="editForm.dependencies.length < availableFields.length"
            @click="addInput"
            class="add-button"
            >+ Add option</span
          >
        </div>
      </div>

      <hyphen-input
        v-model="editForm.placeholder"
        id="dash-input-placeholder"
        placeholder="Enter placeholder text"
        label="Placeholder (optional)"
        description="Placeholder of input you want to create"
      />

      <hyphen-input
        v-model="editForm.description"
        id="dash-input-description"
        placeholder="Enter description text"
        label="Description (optional)"
        description="Description of input you want to create"
      />

      <hyphen-select
        v-model="editForm.required"
        label="Required"
        placeholder="Select a type"
        description="Is this field required?"
        option-label="label"
        option-value="value"
        :options="[
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ]"
      />
    </div>
    <div class="hyphen-input-dash__action">
      <button @click="cancel" class="cancel">Cancel</button>
      <button
        @click="apply"
        v-if="canApply"
        class="apply"
        :class="{
          'apply--disabled': !canApply,
        }"
      >
        Apply
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.hyphen-input-dash {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    color: var(--hyphen-Blue, #19283d);
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 175% */
  }

  &__header {
    padding: 20px;
    margin: 20px 0px;
    border-radius: 4px;
    background: #f8f9fa;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.03);

    .text-hint {
      display: flex;
      align-items: center;
      gap: 10px;

      & > span:nth-child(2) {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 3px;
      }
    }

    p {
      color: var(--body-text, rgba(25, 40, 61, 0.8));
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 183.333% */
    }
  }

  &__content {
    flex: 1;
    overflow: auto;
    gap: 30px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  &__content::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }
  &__content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
  &__content::-webkit-scrollbar-thumb {
    background-color: var(--hyphen-Blue, #19283d);
    border-radius: 10px;
  }

  &__action {
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    padding: 10px 0px;
    margin-bottom: 40px;
    z-index: 99;

    button {
      flex: 1;

      padding: 15px 30px;
      border-radius: 4px;
      border: none;

      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      &.cancel {
        border-radius: 4px;
        background: var(--secondary-button, #f4f5f6);
      }

      &.apply {
        border-radius: 4px;
        color: #fff;
        background: var(--hyphen-Blue, #19283d);
      }
    }
  }
}

.hyphen-input__input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 12px 0px;
  background-color: transparent;

  .input-target {
    flex: 1;
    padding-left: 10px;
  }

  .input-suffix {
    margin-right: 10px;
    display: flex;
    align-items: center;
    width: 16px;
    height: 12px;
    padding-left: 5px;

    .icon-x {
      font-size: 20px;
      color: rgba(217, 222, 225, 1);
      cursor: pointer;
      font-weight: 200;
      &:hover {
        color: red;
      }
    }
  }
}

.add-button {
  text-decoration: underline;
  color: #5e6978;
  font-size: 12px;
  font-family: "Inter";
  font-weight: 400;
  cursor: pointer;
  padding-top: 8px;
}
</style>
