<script setup>
import { defineProps, ref, watch } from "vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },

  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },

  description: {
    type: String,
    default: "",
  },

  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "auto",
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
  error: {
    type: String,
    default: "",
  },
});

const inputValue = ref("");

const emit = defineEmits({
  verificationCode(val) {
    return val;
  },
});

watch(
  () => inputValue.value,
  (val) => {
    emit("verificationCode", val);
  },
  { deep: true }
);
</script>

<template>
  <div class="hyphen-auth">
    <div class="hyphen-input" :style="{ width: width }">
      <label class="hyphen-input__label" :for="props.id">
        {{ props.label }}
      </label>
      <div
        class="hyphen-input__input-wrapper"
        :style="{ backgroundColor: backgroundColor }"
      >
        <input
          class="input-target"
          :id="props.id"
          v-model="inputValue"
          :placeholder="props.placeholder"
          autofocus
        />
      </div>
      <div
        class="hyphen-input__description-text"
        :class="{ 'hyphen-component-error ': props.error }"
      >
        {{ props.error || props.description }}
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hyphen-auth {
  height: 100%;
  min-height: 200px;
  max-height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hyphen-input {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Inter;
  gap: 6px;
  box-sizing: border-box;

  .hyphen-component-error {
    color: #ff0000;
  }

  &__label {
    color: rgba(25, 40, 61, 0.8);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 500;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    border: 1.8px solid #d9dee1;
    border-radius: 5px;
    padding: 12px 0px;
    background-color: transparent;

    input {
      padding: 0px;
      box-sizing: border-box;
    }

    .input-target {
      flex: 1;
      min-width: auto;
      outline-style: none;
      padding: 0px 8px !important;
      box-sizing: border-box !important;
      border-radius: inherit;
      background-color: inherit;
      border: none;
      width: 200px;
      color: #666;
      font-size: 15px;
    }
  }
}
</style>
