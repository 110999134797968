<script setup>
import { computed, defineProps, ref, watch } from "vue";
import { alert } from "../../../svgs.js";
import HyphenInput from "../../base/HyphenInput.vue";
import HyphenSelect from "../../base/HyphenSelect.vue";

const props = defineProps({
  form: {
    type: Object,
    default: null,
  },
});

const editForm = ref({
  type: "",
  value: "",
  label: "",
  placeholder: "",
  description: "",
  required: false,
  max: 100,
  min: 0,
  step: 1,
  unit: null,
});

const emit = defineEmits({
  edit(val) {
    return val;
  },
  remove(val) {
    return val;
  },
  cancelEdit(val) {
    return val;
  },
});

watch(
  () => props.form,
  (val) => {
    if (val) {
      editForm.value = {
        type: val.attributes.type,
        value: val.value,
        label: val.label,
        placeholder: val.attributes.placeholder,
        description: val.attributes.description,
        required: val.required.toString(),
        max: val.attributes.max,
        min: val.attributes.min,
        step: val.attributes.step,
        unit: val.attributes.unit,
      };
    }
  },
  { immediate: true }
);

const canApply = computed(() => {
  return editForm.value.label;
});

const apply = () => {
  const data = {
    id: props.form.id,
    type: props.form.type,
    value: editForm.value.value,
    label: editForm.value.label,
    key: key.value,
    required: editForm.value.required === "true" ? true : false,
    locked: props.form.locked || false,
    attributes: {
      type: editForm.value.type,
      placeholder: editForm.value.placeholder,
      description: editForm.value.description,
      editable: props.form.attributes.editable ?? true,
      max: editForm.value.max ?? 100,
      min: editForm.value.min ?? 0,
      step: editForm.value.step ?? 1,
      unit: editForm.value.unit ?? "",
    },
  };
  emit("edit", data);
};

const key = computed(() => {
  // convert string to snake case
  return editForm.value.label
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/[^\w-]+/g, "");
});

const cancel = () => {
  if (!props.form.label) {
    emit("remove", props.form);
  } else {
    emit("cancelEdit", props.form);
  }
};
</script>
<template>
  <div v-if="editForm" class="hyphen-input-dash">
    <h2>Field Settings</h2>
    <div class="hyphen-input-dash__header">
      <div class="text-hint">
        <span v-html="alert"></span><span>Range Selector</span>
      </div>
      <p>
        A compact, user-friendly input box ideal for collecting concise
        responses such as names, amount, or any other short textual data.
      </p>
    </div>

    <div class="hyphen-input-dash__content">
      <hyphen-input
        v-model="editForm.label"
        autofocus
        id="dash-input-label"
        placeholder="Enter label"
        label="Label"
        description="Label of input you want to create"
      />

      <hyphen-select
        v-model="editForm.type"
        label="Input Type"
        placeholder="Select a type"
        description="Type of input you want to create"
        option-label="label"
        option-value="value"
        :options="[
          {
            label: 'Number',
            value: 'number',
          },
          {
            label: 'Money',
            value: 'money',
          },
        ]"
      />

      <hyphen-input
        v-model="editForm.min"
        id="dash-input-label"
        placeholder="Enter min value"
        type="number"
        label="Minimum Value"
        description="Minimum value of the range"
      />

      <hyphen-input
        v-model="editForm.max"
        id="dash-input-label"
        placeholder="Enter max value"
        type="number"
        label="Maximum Value"
        description="Maximum value of the range"
      />

      <!-- progression -->
      <hyphen-input
        v-model="editForm.step"
        id="dash-input-label"
        placeholder="Enter step value"
        type="number"
        label="Step Value"
        description="Progression of the range value"
      />

      <hyphen-input
        v-model="editForm.unit"
        id="dash-input-label"
        placeholder="Enter Prefix (optional)"
        label="Prefix"
        description="Prefix of the range value"
      />

      <hyphen-input
        v-model="editForm.placeholder"
        id="dash-input-placeholder"
        placeholder="Enter placeholder text"
        label="Placeholder (optional)"
        description="Placeholder of input you want to create"
      />

      <hyphen-input
        v-model="editForm.description"
        id="dash-input-description"
        placeholder="Enter description text"
        label="Description (optional)"
        description="Description of input you want to create"
      />

      <hyphen-input
        v-model="editForm.value"
        id="dash-input-value"
        placeholder="Enter default value"
        label="Value (optional)"
        :type="editForm.type"
        description="Default value of input you want to create"
      />

      <hyphen-select
        v-model="editForm.required"
        label="Required"
        placeholder="Select a type"
        description="Is this field required?"
        option-label="label"
        option-value="value"
        :options="[
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ]"
      />
    </div>
    <div class="hyphen-input-dash__action">
      <button @click="cancel" class="cancel">Cancel</button>
      <button
        @click="apply"
        v-if="canApply"
        class="apply"
        :class="{
          'apply--disabled': !canApply,
        }"
      >
        Apply
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.hyphen-input-dash {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    color: var(--hyphen-Blue, #19283d);
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 175% */
  }

  &__header {
    padding: 20px;
    margin: 20px 0px;
    border-radius: 4px;
    background: #f8f9fa;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.03);

    .text-hint {
      display: flex;
      align-items: center;
      gap: 10px;

      & > span:nth-child(2) {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 3px;
      }
    }

    p {
      color: var(--body-text, rgba(25, 40, 61, 0.8));
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 183.333% */
    }
  }

  &__content {
    flex: 1;
    overflow: auto;
    gap: 30px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  &__content::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }
  &__content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
  &__content::-webkit-scrollbar-thumb {
    background-color: var(--hyphen-Blue, #19283d);
    border-radius: 10px;
  }

  &__action {
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    padding: 10px 0px;
    margin-bottom: 40px;
    z-index: 99;

    button {
      flex: 1;

      padding: 15px 30px;
      border-radius: 4px;
      border: none;

      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      &.cancel {
        border-radius: 4px;
        background: var(--secondary-button, #f4f5f6);
      }

      &.apply {
        border-radius: 4px;
        color: #fff;
        background: var(--hyphen-Blue, #19283d);
      }
    }
  }
}
</style>
